













import router from '@/router'
import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
    setup() {
        const isArchivedList = ref(false)
        const path = ref('')
        const routerTransition = ref({
            enter: '',
            leave: '',
        })

        //add transition only when routing to archived list component
        const setTransition = () => {
            routerTransition.value = {
                enter: 'animated slideInRight',
                leave: 'animated slideOutLeft',
            }
        }

        // watch for url change
        watch(
            () => router.app.$route,
            () => {
                path.value = router.app.$route.path
                // etract `archived-list` string from path
                isArchivedList.value = path.value.split('/').includes('archived-list')
                if (isArchivedList.value) setTransition()
            },
            {
                immediate: true,
            }
        )
        return {
            isArchivedList,
            path,
            routerTransition,
        }
    },
})
